<template>
  <footer class="main-footer sticky footer-type-1">
    <div class="footer-inner">
      <!-- 版权信息 -->
      <div class="footer-text">
        &copy; 2017-2019
        <a href="#"><strong>WebStack</strong></a> 
        design by <a href="http://viggoz.com" target="_blank"><strong>Viggo</strong></a> | <a href="https://beian.miit.gov.cn/">闽ICP备17033030号-3</a>
      </div>
      
      <div class="go-up">
        <a href="#" rel="go-top"><i class="fa-angle-up"></i></a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style>

</style>
